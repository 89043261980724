import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useFormState } from 'react-final-form';
import api from '../../../../../../../api';
import useHeightCollapsible from '../../../../../../../hooks/useHeightCollapsible';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';
import PortalManagementService from '../../../../services/PortalManagement.service';
import StylingLegalEntityForm from './components/StylingLegalEntityForm';
import StylingNaturalPersonForm from './components/StylingNaturalPersonForm';
import './styles.scss';

const Styling = () => {
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);
  const [fontsArray, setFontsArray] = useState([]);
  const [fontsLoading, setFontsLoading] = useState(false);
  const baseClass = 'ickyc-widget-styling-form';
  const setSuffix = setClassSuffix(baseClass);

  const { values } = useFormState();
  const widgetRef = useRef();

  useEffect(() => {
    const portalConfig = PortalManagementService.normalizePortalConfiguration(values);
    const adaptedPortalConfig = {
      ...portalConfig,
      styling: {
        ...portalConfig.styling,
        clientLogo: {
          ...portalConfig?.styling?.clientLogo,
          imgUrl: portalConfig?.styling?.clientLogo?.imgUrl?.preview || portalConfig?.styling?.clientLogo?.imgUrl,
        },
      },
    };

    if (values.type === 'NaturalPerson') {
      if (!isWidgetLoaded) {
        window.iComplyNPPortalPreview.init({
          element: 'widget-container',
          readOnlyConfigData: adaptedPortalConfig,
        });
        setIsWidgetLoaded(true);
      } else {
        window.iComplyNPPortalPreview.updateConfig(adaptedPortalConfig);
      }
    } else {
      if (!isWidgetLoaded) {
        window.iComplyLEPortalPreview.init({
          element: 'widget-container',
          readOnlyConfigData: adaptedPortalConfig,
        });
        setIsWidgetLoaded(true);
      } else {
        window.iComplyLEPortalPreview.updateConfig(adaptedPortalConfig);
      }
    }
  }, [widgetRef.current, values, isWidgetLoaded]);

  useEffect(() => {
    const loadWebFontsList = async () => {
      setFontsLoading(true);
      try {
        const responseData = await api.getWebFonts();
        const { items } = await responseData.json();
        setFontsArray(
          items.map(({ family }) => ({
            id: family,
            label: family,
            value: family,
          })),
        );
      } catch (error) {
        console.error('Failed to load web fonts:', error);
      } finally {
        setFontsLoading(false);
      }
    };
    loadWebFontsList();
  }, []);

  const logoContainerRef = useRef();

  const {
    styling: { clientLogo },
  } = values;

  const { isOpen, toggleView } = useHeightCollapsible(logoContainerRef, clientLogo?.enabled);
  const logoContainerClass = classNames({
    [setSuffix('__content__logo__container')]: true,
    [setSuffix('__content__logo__container--expanded')]: isOpen,
  });

  return (
    <div className={baseClass}>
      <div className={setSuffix('__content')}>
        {values.type === 'NaturalPerson' ? (
          <StylingNaturalPersonForm
            logoContainerClass={logoContainerClass}
            logoContainerRef={logoContainerRef}
            toggleView={toggleView}
            fontsArray={fontsArray}
            fontsLoading={fontsLoading}
            baseClass={baseClass}
          />
        ) : (
          <StylingLegalEntityForm
            logoContainerClass={logoContainerClass}
            logoContainerRef={logoContainerRef}
            toggleView={toggleView}
            fontsArray={fontsArray}
            fontsLoading={fontsLoading}
            baseClass={baseClass}
          />
        )}
      </div>
      <div className={setSuffix('__mobile-screen')}>
        <img src="/iphoneFrame.png" alt="iphone-frame" />
        <div className={setSuffix('__widget-container')} id="widget-container" />
      </div>
    </div>
  );
};

export default Styling;
