/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Field, useField, useForm, useFormState } from 'react-final-form';
import DeleteButton from '../../../../../../../components/Buttons/DeleteButton';
import CountrySelectField from '../../../../../../../components/CountrySelectField';
import DatePickerField from '../../../../../../../components/DatepickerField';
import InputField from '../../../../../../../components/InputField';
import SelectContent from '../../../../../../../components/Select/components/SelectContent';
import SelectCustomContentSpecial from '../../../../../../../components/Select/components/SelectCustomContentSpecial';
import SelectFilterContent from '../../../../../../../components/Select/components/SelectFilterContent';
import SelectInputTrigger from '../../../../../../../components/Select/components/SelectInputTrigger';
import SelectTrigger from '../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../components/SelectField';
import utilities from '../../../../../../../utilities';
import enums from '../../../../../../../utilities/enums';

/**
 * @param {string} namePreffix - preffix attached to all field name (e.g if component is used in FieldArray)
 * @param {*} param0
 */

const AffiliatedEntitiesFields = ({
  index,
  className,
  entities: entitiesList,
  currentEntityindex,
  namePreffix,
  removeRow,
  changeParams,
  dirtySinceLastSubmit,
  submitError,
  isOnlyAffilitedEntity,
}) => {
  const { change, batch } = useForm();
  const errCustomMsg = 'An error occurred, check affiliated entities';
  const {
    values: { entities },
  } = useFormState();

  const {
    input: {
      value: { type: selectedEntityType, id: entityId, name: entityName, primaryContactId },
    },
  } = useField(namePreffix);

  const isLeEntitySElected = useMemo(() => selectedEntityType === enums.ENTITY_TYPE_NAMES.LEGAL_ENTITY, [
    selectedEntityType,
  ]);

  const isNpSelected = useMemo(() => selectedEntityType === enums.ENTITY_TYPE_NAMES.NATURAL_PERSON, [
    selectedEntityType,
  ]);

  const isDisabled = selectedEntityType === undefined || selectedEntityType === '' || !entityName;

  const handlePrimaryContactChange = () => {
    batch(() => {
      entities[currentEntityindex].affiliatedEntities.forEach((element, i) => {
        change(`entities[${currentEntityindex}].affiliatedEntities[${i}].primaryContact`, false);
      });
      change(`${namePreffix}.primaryContact`, true);
    });
  };

  const {
    input: { value: primaryContactValue },
  } = useField(`${namePreffix}.primaryContact`);

  const isExistinEntity = useMemo(() => {
    return (isLeEntitySElected || isNpSelected) && entityId;
  }, [isLeEntitySElected, isNpSelected, entityId]);

  const handleEntitySelection = useCallback(
    entity => {
      const { residenceId, name, type = selectedEntityType, email, date, id, primaryContact } = entity;
      const primaryContactData = {};

      if (type === enums.ENTITY_TYPE_NAMES.LEGAL_ENTITY && primaryContact) {
        const { email: primaryContactEmail, name: primaryContactName, id: primaryContactId } = primaryContact;
        primaryContactData.primaryContactId = primaryContactId;
        primaryContactData.email = primaryContactEmail;
        primaryContactData.primaryContactName = primaryContactName;
      }
      change(`${namePreffix}`, {
        entitySearch: name,
        primaryContact: !!primaryContactValue,
        name,
        date,
        email,
        type: type || enums.ENTITY_TYPE_NAMES.NATURAL_PERSON,
        id,
        residenceId,
        ...primaryContactData,
      });

      type ?? document.getElementById('email').focus();
    },
    [change, namePreffix, primaryContactValue, selectedEntityType],
  );

  const primaryContactCallback = useCallback(
    primaryContact => {
      const { id, name, date, email } = primaryContact;

      if (id) {
        change(`${namePreffix}.primaryContactName`, name);
        change(`${namePreffix}.primaryContactId`, id);
        change(`${namePreffix}.email`, email);
        change(`${namePreffix}.date`, date);
      } else {
        const nameParts = name.split(' ');
        const firstname = nameParts[0];
        const lastName = nameParts.length > 2 ? nameParts.slice(1).join(' ') : nameParts[1];

        change(`${namePreffix}.primarySearch`, firstname);
        change(`${namePreffix}.primaryContactName`, firstname);
        change(`${namePreffix}.firstName`, firstname);
        change(`${namePreffix}.lastName`, lastName);
        change(`${namePreffix}.primaryContactId`, undefined);
      }
    },
    [change, namePreffix],
  );

  const changePrimaryContactParams = useCallback(
    params => {
      changeParams({ ...params, entityType: 0 });
    },
    [changeParams],
  );

  const changeEntityParams = useCallback(
    params => {
      changeParams({ ...params, entityType: selectedEntityType || 0 });
    },
    [changeParams, selectedEntityType],
  );

  const emailFieldsProps = useMemo(() => {
    if (isNpSelected) {
      return {
        placeholder: 'Email',
        preview: isNpSelected && isExistinEntity,
        disabled: isDisabled,
      };
    }
    if (isLeEntitySElected)
      return {
        placeholder: 'Primary Contact Email',
        disabled: isDisabled,
        preview: isLeEntitySElected && isExistinEntity,
      };
    return {
      disabled: true,
    };
  }, [isNpSelected, isExistinEntity, isDisabled, isLeEntitySElected]);

  const dateFieldProps = useMemo(() => {
    if (isNpSelected) {
      return {
        preview: isNpSelected && isExistinEntity,
        disabled: isDisabled,
        placeholder: 'Date of Birth',
      };
    }
    if (isLeEntitySElected) {
      return {
        placeholder: 'Formation Date',
        disabled: isDisabled,
        preview: isLeEntitySElected && isExistinEntity,
      };
    }
    return {
      disabled: true,
    };
  }, [isNpSelected, isExistinEntity, isDisabled, isLeEntitySElected]);

  const lastNameFieldProps = useMemo(() => {
    if (isNpSelected) {
      return {
        preview: isNpSelected && isExistinEntity,
        disabled: isDisabled,
        placeholder: !isExistinEntity && 'Last Name',
      };
    }
    if (isLeEntitySElected)
      return {
        preview: isLeEntitySElected && isExistinEntity,
        disabled: isDisabled,
        placeholder: !isExistinEntity && 'Last Name',
      };
    return {
      disabled: true,
    };
  }, [isNpSelected, isExistinEntity, isDisabled, isLeEntitySElected]);
  return (
    <div className={className}>
      <div className={`${className}__w1`}>
        <Field
          name={`${namePreffix}.type`}
          component={SelectField}
          Trigger={<SelectTrigger placeholder="Entity Type" />}
          Content={<SelectFilterContent />}
          renderAsPortal
          initialValue={enums.ENTITY_TYPE_NAMES.NATURAL_PERSON}
          preview={isExistinEntity}
          options={enums.ENTITY_TYPES}
          className={`${className}__entity-type-select`}
        />
      </div>
      <div className={`${className}__primary-contact`}>
        <Field
          disabled={isDisabled}
          name={`${namePreffix}.primaryContact`}
          hidden={!isNpSelected}
          type="radio"
          value
          onChange={handlePrimaryContactChange}
          component="input"
        />
      </div>
      {isNpSelected && (
        <>
          <span className="primary-contact-text">Primary Contact</span>
        </>
      )}
      {isLeEntitySElected && (
        <div className="ickyc-primary-contact-name">
          {isDisabled || (isLeEntitySElected && isExistinEntity) ? (
            <div />
          ) : (
            <Field
              name={`${namePreffix}.primaryContactName`}
              disabled={isDisabled || (isLeEntitySElected && isExistinEntity)}
              preview={isLeEntitySElected && isExistinEntity}
              component={SelectField}
              Trigger={
                <SelectInputTrigger
                  placeholder="Primary Contact Name"
                  autoFocus
                  wrapWithField
                  disableToggleView
                  inputFieldName={`${namePreffix}.primarySearch`}
                />
              }
              Content={
                <SelectCustomContentSpecial
                  inputFieldName={`${namePreffix}.primarySearch`}
                  callback={primaryContactCallback}
                  changeParams={changePrimaryContactParams}
                />
              }
              options={entitiesList}
              specialContentTabbing
              renderAsPortal
              useFullValue
            />
          )}
        </div>
      )}
      {!isLeEntitySElected && !isNpSelected && <div />}
      <Field name={`${namePreffix}.email`} {...emailFieldsProps} component={InputField} id="email" />
      <Field name={`${namePreffix}.date`} {...dateFieldProps} component={DatePickerField} />
      <Field
        name={`${namePreffix}.residenceId`}
        placeholder="Residence"
        component={CountrySelectField}
        className={`${className}--w2`}
        disabled={isDisabled}
        renderAsPortal
      />
      <Field
        className={`${className}__entity-search`}
        component={SelectField}
        Trigger={
          <SelectInputTrigger
            placeholder="Enter New Name or Search"
            autoFocus
            wrapWithField
            disableToggleView
            inputFieldName={`${namePreffix}.entitySearch`}
          />
        }
        Content={
          <SelectCustomContentSpecial
            inputFieldName={`${namePreffix}.entitySearch`}
            callback={handleEntitySelection}
            changeParams={changeEntityParams}
          />
        }
        options={entitiesList}
        specialContentTabbing
        name={`${namePreffix}.entity`}
        renderAsPortal
        useFullValue
      />
      {!primaryContactId && (
        <Field
          name={`${namePreffix}.lastName`}
          {...lastNameFieldProps}
          component={InputField}
          parse={utilities.parseName}
          className={`${className}--w1`}
        />
      )}
      {!isLeEntitySElected ? (
        <Field
          name={`${namePreffix}.control`}
          disabled={isDisabled || !isNpSelected}
          component={SelectField}
          multipleSelection
          renderAsPortal
          options={enums.NATURAL_PERSON_CONTROL_TAGS}
          className={`${className}--w1`}
          Trigger={<SelectInputTrigger placeholder={isNpSelected ? 'Control' : ''} />}
          Content={<SelectContent />}
        />
      ) : (
        <>
          <div className={`${className}--w1`} />
        </>
      )}
      <Field
        name={`${namePreffix}.ownershipPercentage`}
        placeholder="Ownership"
        suffix={<span>%</span>}
        component={InputField}
        type="number"
        disabled={isDisabled}
        className={`${className}--w1`}
      />
      <Field
        name={`${namePreffix}.ownershipCategories`}
        component={SelectField}
        disabled={isDisabled}
        options={enums.OWNERSHIP_TAGS}
        renderAsPortal
        multipleSelection
        className={`${className}--w2`}
        Trigger={<SelectInputTrigger placeholder="Ownership Categories" />}
        Content={<SelectContent />}
      />
      <div className="remove-wrapper">
        <div className={`${className}__remove`}>
          {!isOnlyAffilitedEntity && !primaryContactValue && <DeleteButton onClick={() => removeRow(index)} />}
        </div>
      </div>
      {submitError?.length > 0 &&
        submitError[index] &&
        submitError[index]?.message !== errCustomMsg &&
        submitError[index]?.index === 'index-error' &&
        !dirtySinceLastSubmit && <div className={`${className}__error`}>{submitError[index]?.message}</div>}
    </div>
  );
};

AffiliatedEntitiesFields.propTypes = {
  namePreffix: PropTypes.string,
  removeRow: PropTypes.func.isRequired,
  index: PropTypes.number,
  className: PropTypes.string,
  entities: PropTypes.arrayOf(PropTypes.shape({})),
  currentEntityindex: PropTypes.number.isRequired,
  changeParams: PropTypes.func.isRequired,
  dirtySinceLastSubmit: PropTypes.bool,
  submitError: PropTypes.arrayOf(PropTypes.shape({})),
  isOnlyAffilitedEntity: PropTypes.bool,
};
AffiliatedEntitiesFields.defaultProps = {
  namePreffix: '',
  index: 0,
  className: undefined,
  entities: [],
  dirtySinceLastSubmit: false,
  submitError: [],
  isOnlyAffilitedEntity: undefined,
};
export default AffiliatedEntitiesFields;
