import api from 'api';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { EntityContext, RCUContext } from 'utilities/services/contexts';
import Accordion from '../../../../../../../../../components/Accordion';
import SelectFilterContent from '../../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import ToggleField from '../../../../../../../../../components/ToggleField';
import useEffectSkipFirst from '../../../../../../../../../hooks/useEffectSkipFirst';
import utilities from '../../../../../../../../../utilities';
import kycServicesEnums from '../../../kycServicesEnums';
import './styles.scss';

const DocumentAuthenticationRCU = ({ namePrefix }) => {
  const baseClass = 'ickyc-docment-auth-rcu';
  const { entityId } = useContext(EntityContext);
  const [faceMatchOptions, setFaceMatchOptions] = useState([]);
  const {
    initialConfiguration: { biometricAuthenticationEnabled: biometricAuthVisible },
  } = useContext(RCUContext);

  const { values } = useFormState();

  const { kycRequests, dataCollection } = values[namePrefix]
    ? { kycRequests: values[namePrefix].kycRequests, dataCollection: values[namePrefix].dataCollection }
    : { kycRequests: values.kycRequests, dataCollection: values.dataCollection };

  const { batch, change } = useForm();

  const namePrefixWithDot = namePrefix ? `${namePrefix}.` : '';

  const handleDocumentAuthenticationChange = useCallback(documentAuthenticationValue => {
    batch(() => {
      change(
        `${namePrefixWithDot}kycRequests.documentAuthentication.templateVerification`,
        documentAuthenticationValue,
      );
      change(
        `${namePrefixWithDot}kycRequests.documentAuthentication.documentDataVerification`,
        documentAuthenticationValue,
      );
      change(`${namePrefixWithDot}kycRequests.documentAuthentication.includeFaceMatch`, documentAuthenticationValue);
      // synced
      change(`${namePrefixWithDot}dataCollection.requestIdentityDocument`, documentAuthenticationValue);
    }, []);
  }, []);

  const disableIfAllSubTOgglesAreTurnedOff = useCallback(
    (value, name) => {
      if (!value) {
        if (
          (!kycRequests?.documentAuthentication?.templateVerification || name === 'templateVerification') &&
          (!kycRequests?.documentAuthentication?.documentDataVerification || name === 'documentDataVerification') &&
          (!kycRequests?.documentAuthentication?.includeFaceMatch || name === 'includeFaceMatch')
        ) {
          change(`${namePrefixWithDot}kycRequests.documentAuthentication.isEnabled`, false);
        }
      }
    },
    [
      kycRequests?.documentAuthentication?.templateVerification,
      kycRequests?.documentAuthentication?.documentDataVerification,
      kycRequests?.documentAuthentication?.includeFaceMatch,
    ],
  );

  useEffect(() => {
    const faceMatchOptions = async () => {
      try {
        const {
          data: { data },
        } = await api.kyc.entityManagement.naturalPerson.getBiometricTable({ entityId, includeAcceptedOnly: true });

        setFaceMatchOptions(
          kycServicesEnums.INCLUDE_FACE_MATCH_OPTIONS.map(option =>
            data?.length || option?.value === '1' ? option : { ...option, disabled: true },
          ),
        );
      } catch (error) {
        console.error(error);
      }
    };

    faceMatchOptions();
  }, [entityId]);

  useEffectSkipFirst(() => {
    change(`${namePrefixWithDot}kycRequests.documentAuthentication.isEnabled`, dataCollection?.requestIdentityDocument);
  }, [dataCollection?.requestIdentityDocument]);

  return (
    <Accordion
      title={
        <>
          <div onClick={utilities.handleOnClickEvent}>
            <Field
              preview
              name={`${namePrefixWithDot}kycRequests.documentAuthentication.isEnabled`}
              component={ToggleField}
              label="Document Authentication"
              onChange={handleDocumentAuthenticationChange}
            />
          </div>
          <span className="ickyc-spacer" />
        </>
      }
      accordionOpen={kycRequests?.documentAuthentication?.isEnabled}
    >
      <div className={baseClass}>
        <Field
          name={`${namePrefixWithDot}kycRequests.documentAuthentication.templateVerification`}
          component={ToggleField}
          label="Run KYC Service: Template Verification"
          onChange={value => disableIfAllSubTOgglesAreTurnedOff(value, 'templateVerification')}
        />
        <Field
          name={`${namePrefixWithDot}kycRequests.documentAuthentication.documentDataVerification`}
          component={ToggleField}
          label="Run KYC Service: Document Data Verification"
          onChange={value => disableIfAllSubTOgglesAreTurnedOff(value, 'documentDataVerification')}
        />
        {biometricAuthVisible && (
          <div className="ickyc-docment-auth-rcu__include-face-match">
            <Field
              name={`${namePrefixWithDot}kycRequests.documentAuthentication.faceMatchStrategy`}
              label={
                <Field
                  name={`${namePrefixWithDot}kycRequests.documentAuthentication.includeFaceMatch`}
                  component={ToggleField}
                  label="Include Face Match"
                  onChange={value => disableIfAllSubTOgglesAreTurnedOff(value, 'includeFaceMatch')}
                />
              }
              component={SelectField}
              renderAsPortal
              options={faceMatchOptions}
              Trigger={<SelectTrigger placeholder="Face Match or Existing Record" />}
              Content={<SelectFilterContent />}
            />
          </div>
        )}
      </div>
    </Accordion>
  );
};
export default DocumentAuthenticationRCU;
