import api from 'api';
import FormModal from 'components/Modal/FormModal';
import { FORM_ERROR } from 'final-form';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserAccessByLicense } from 'store/selectors/auth.selector';
import toastr from 'toastr';
import enums from 'utilities/enums';
import authEnums from 'utilities/enums/authEnums';
import DateManager from 'utilities/services/DateManager';
import ModalContent from './components/ModalContent';
import './styles.scss';

const NaturalPersonModal = ({ hideModal, setNewlyAdded }) => {
  const platformAccess = useSelector(selectUserAccessByLicense);

  const hasAccesToCasesByLicence = useMemo(() => {
    return platformAccess.some(el => el === authEnums.ACCESS_BY_LICENCE.CASES);
  }, [platformAccess]);

  const [initialValues] = useState({
    identityVerification: false,
    facialMatching: false,
    documentAuthentication: false,
    amlScreening: hasAccesToCasesByLicence,
    documentType: 'passport',
  });
  const [successIds, setSuccessIds] = useState([]);

  const [hasEntities, setHasEntities] = useState(false);

  const containerRef = useRef();

  const handleSubmit = async values => {
    const mapToTableState = extractInfo => {
      return extractInfo.map(({ entityId, index, issuesCount }) => {
        const np = values.persons.find((el, ind) => ind === index);
        if (np) {
          return {
            assigned: 'No',
            countryId: np.data.nationality,
            date: DateManager.getLocal(),
            entityId,
            issues: issuesCount,
            name: `${np.data.firstName} ${np.data.lastName}`,
            risk: 0,
            status: 'New',
            type: 'Natural Person',
            lastUpdated: DateManager.getLocal(),
          };
        }
        return undefined;
      });
    };
    try {
      const responseVals = await api.kyc.entityManagement.naturalPerson.createNew({
        ...values,
        persons: values?.persons?.map(person => ({
          ...person,
          data: {
            ...person.data,
            birthDate: DateManager.toBekend(person?.data?.birthDate),
          },
        })),
      });

      const { data: response } = responseVals;
      let successCreated = [];
      response.forEach(({ created, entityId, issuesCount }, index) => {
        if (created) {
          successCreated = [...successCreated, { entityId, index, issuesCount }];
        }
      });
      // setnewly added
      const createdEntitites = mapToTableState(successCreated);
      createdEntitites.forEach(({ name }) => {
        toastr.success(`Natural Person:  ${name} is created`, { timeOut: 4000 });
      });
      setNewlyAdded(createdEntitites);
      hideModal();
    } catch (err) {
      if (err?.response) {
        const { status, data: errorData } = err.response;

        if (status === 400 && errorData.message) {
          return { [FORM_ERROR]: errorData.message };
        }
        if (status >= 400 && status < 500) {
          const errors = {};
          const persons = Array(errorData.length).fill({});
          let successCreated = [];
          errorData.forEach(({ created, entityId, errors: resErrors }, index) => {
            persons[index] = {};

            if (!created) {
              persons[index].index = 'index-error';
              const [firstError] = resErrors;
              persons[index] = firstError;
            } else {
              successCreated = [...successCreated, { entityId, index }];
            }
          });
          // setnewly added
          const createdEntitites = mapToTableState(successCreated);
          createdEntitites.forEach(({ name }) => {
            toastr.success(`Natural Person:  ${name} is created`, { timeOut: 4000 });
          });
          setNewlyAdded(createdEntitites);
          setSuccessIds(successCreated.map(ce => ce.index));
          if (values.persons.length !== successCreated.length) {
            errors[
              FORM_ERROR
            ] = `You have added ${successCreated.length} Natural Person(s), please review and resubmit the following records`;
            errors.persons = persons;
            return errors;
          }
        }
        if (status === 500) {
          return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
        }
      }
    }
  };

  useEffect(() => {
    const handler = () => {
      containerRef.current.scrollTo({ top: containerRef.current.scrollHeight, behavior: 'smooth' });
    };

    bus.addEventListener(enums.BUS_EVENTS.SCROLL_BOTTOM_MODAL, handler);
    return () => bus.removeEventListener(enums.BUS_EVENTS.SCROLL_BOTTOM_MODAL, handler);
  }, []);

  return (
    <FormModal
      title="Add Natural Person"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      hideModal={hideModal}
      className="ickyc-natural-person-modal"
      withFieldArray
      showErrorsInConfirmation
      ref={containerRef}
      disableSubmitButton={!hasEntities}
      disableSubmitWhenErrorMessageIsDisplayed
      validate={values => {
        const errors = {};
        if (!values.persons?.length) {
          errors.persons = 'Required';
        }
        return errors;
      }}
    >
      <ModalContent setHasEntities={setHasEntities} successIds={successIds} />
    </FormModal>
  );
};

NaturalPersonModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  setNewlyAdded: PropTypes.func.isRequired,
};

export default NaturalPersonModal;
