/* eslint-disable no-console */
import api from 'api';
import WizardFormPage from 'components/Modal/WizardFormModal/components/WizardFormPage';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import toastr from 'toastr';
import enums from 'utilities/enums';
import AddLegalEntitiesStep from './AddLegalEntityStep';
import LinkAffiliatedEntitiesStep from './LinkAffiliatedEntitiesStep';
import V2Modal from './components/V2Modal';
import './styles.scss';
import utils from './utils';

/**
 * Two-step modal for creating incorporated entity.
 * @param {reference} containerRef - container reference used in the modal
 * @param {function} hideModal - function for hiding the active modal
 */

const normalizeAffiliatedEntityArray = array => {
  return array.map(ae => {
    const { entity, entityId, entitySearch, ...rest } = ae;
    const data = { ...rest };
    if (entityId) {
      data.id = entityId;
    }
    if (!entityId && ae?.type === '1') {
      data.firstName = ae?.primarySearch;
    }
    return data;
  });
};

const normalizeSubmitObject = values => {
  return values.entities.map(e => {
    const { affiliatedEntities, jurisdictionOfTaxResidenceId, ...restOfEntityInfo } = e;
    const submitObject = {
      //TODO: once jurisdictionOfTaxResidenceId become field array delete this
      taxJurisdictions: jurisdictionOfTaxResidenceId ? [{ countryId: jurisdictionOfTaxResidenceId }] : [],
      ...restOfEntityInfo,
    };
    if (affiliatedEntities) {
      submitObject.affiliatedEntities = normalizeAffiliatedEntityArray(affiliatedEntities);
    }
    return submitObject;
  });
};

const LegalEntityModal = ({ hideModal, setNewlyAdded }) => {
  const [successIndexes, setSuccessIndexes] = useState([]);
  const [entitiesToGetValidated, setEntitiesToGetValidated] = useState([]);

  const handleSuccesfulyCreatedEntities = useCallback(errorData => {
    let successCreated = [];
    const errorObj = Array(errorData.length).fill({});
    errorData.forEach(({ created, errors: resErrors, entityDto }, index) => {
      errorObj[index] = {};
      if (!created && resErrors) {
        errorObj[index].index = 'index-error';
        const [firstError] = resErrors || [''];
        if (firstError === 'First name is missing') {
          errorObj[index].message = 'Name is missing';
        } else {
          errorObj[index].message = firstError || 'An error occurred, check affiliated entities';
        }
      } else {
        successCreated = [...successCreated, { entityDto, index }];
      }
      errorObj[index].created = created;
    });

    return { successCreated, errorObj };
  }, []);

  const handleSubmissionSuccess = useCallback(
    response => {
      let successCreated = [];
      response.forEach(({ created, entityDto }, index) => {
        if (created) {
          successCreated = [...successCreated, { entityDto, index }];
        }
      });
      const createdEntitites = successCreated.map(({ entityDto }) => entityDto);
      createdEntitites.forEach(({ name }) => {
        toastr.success(`Legal Entity:  ${name} is created`, { timeOut: 4000 });
      });
      setNewlyAdded(createdEntitites);
      hideModal();
    },
    [hideModal, setNewlyAdded],
  );

  const handleSubmit = async (values, form) => {
    const errors = {};
    const errStrings = [];
    const { batch, change } = form;

    values.entities.forEach((ent, index) => {
      if (ent?.affiliatedEntities && ent?.affiliatedEntities.length) {
        if (
          !ent.affiliatedEntities.some(affEntity => {
            return affEntity?.type === enums.ENTITY_TYPE_NAMES.NATURAL_PERSON ? affEntity?.primaryContact : false;
          })
        ) {
          errStrings.push(`Legal Entity: ${index + 1} ${ent.name} has no primary contact selected`);
        }
      } else {
        errStrings.push(
          `Legal Entity: ${index + 1} ${ent.name} has no Affiliated Entity selected, no primary contact selected`,
        );
      }
    });
    if (errStrings.length) {
      errors[FORM_ERROR] = errStrings;
      return errors;
    }

    if (values.entities.length === 0) return;

    let submitObject = {};
    if (successIndexes.length > 0) {
      const newEntitesArray = [];
      values.entities.forEach((entity, index) => {
        if (!successIndexes.some(el => el === index)) {
          newEntitesArray.push(entity);
        }
      });
      batch(() => {
        change('entities', newEntitesArray);
      });
      submitObject = normalizeSubmitObject({ entities: newEntitesArray });
    } else {
      submitObject = normalizeSubmitObject(values);
    }

    return api.kyc.entityManagement.legalEntity
      .createNew({
        legalEntities: submitObject,
      })
      .then(({ data: response }) => {
        handleSubmissionSuccess(response);
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            if (errorData.message) return { [FORM_ERROR]: errorData.message };

            const { successCreated: successfullyCreated, errorObj } = handleSuccesfulyCreatedEntities(errorData);

            errorData.forEach((el, index) => {
              const { errorObj: aeErrorsObject } = handleSuccesfulyCreatedEntities(el.affiliatedEntitiesErrors);
              if (typeof errorObj[index] !== 'string') errorObj[index].affiliatedEntities = aeErrorsObject;
            });

            const createdEntitites = successfullyCreated.map(({ entityDto }) => entityDto);
            createdEntitites.forEach(({ name }) => {
              toastr.success(`Legal Entity:  ${name} is created`, { timeOut: 4000 });
            });
            setNewlyAdded(createdEntitites);
            setSuccessIndexes(successfullyCreated.map(ce => ce.index));
            if (values.entities.length !== successfullyCreated.length) {
              const globalModalErrors = [];
              globalModalErrors.push(
                `You have added ${successfullyCreated.length} Legal Entities(s), please review and resubmit the following records`,
              );
              errorObj.forEach(LeErr => {
                if (!LeErr?.created) {
                  globalModalErrors.push(LeErr?.message);
                }
              });
              errors[FORM_ERROR] = globalModalErrors;
              errors.entities = errorObj;
              return errors;
            }
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }

          return { [FORM_ERROR]: errorData.errors[0] };
        }
        return { [FORM_ERROR]: 'Error occured while creating Legal Entity' };
      });
  };

  const handleInvalidSubmit = useCallback(values => {
    const temp = values?.entities?.reduce((prev, { address }, index) => {
      const { countryId, ...restAddress } = address || {};
      if (utils.isSomeObjectValueThruthy(restAddress) && !countryId) {
        return [...prev, index];
      } else {
        return prev;
      }
    }, []);

    setEntitiesToGetValidated(temp);

    if (temp) {
      return temp.length > 0;
    }
  }, []);

  const formValidation = useCallback(values => {
    const errors = values?.entities?.reduce((prev, { address }) => {
      const { countryId, ...restAddress } = address || {};
      if (utils.isSomeObjectValueThruthy(restAddress) && !countryId) {
        return [
          ...prev,
          {
            address: {
              countryId: 'Required',
            },
          },
        ];
      } else return [...prev, undefined];
    }, []);

    return { entities: errors };
  }, []);

  return (
    <V2Modal
      onSubmitHandler={handleSubmit}
      validate={formValidation}
      onInvalidSubmit={handleInvalidSubmit}
      hideModal={hideModal}
      initialValues={{
        organizationSearch: { source: 'lei' },
      }}
      disableSubmitWhenErrorMessageIsDisplayed
      className="ickyc-legal-entity-modal"
      titles={[
        <h2>
          Step 1: &nbsp;
          <b>Add Legal Entities</b>
        </h2>,
        <h2>
          Step 2: &nbsp;
          <b>Link Affiliated Entities</b>
        </h2>,
      ]}
      withFieldArray
    >
      <WizardFormPage>
        <AddLegalEntitiesStep entitiesToGetValidated={entitiesToGetValidated} />
      </WizardFormPage>
      <WizardFormPage>
        <LinkAffiliatedEntitiesStep />
      </WizardFormPage>
    </V2Modal>
  );
};

LegalEntityModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  setNewlyAdded: PropTypes.func,
};
LegalEntityModal.defaultProps = {
  setNewlyAdded: () => {},
};

export default LegalEntityModal;
