import api from 'api';
import SelectContent from 'components/Select/components/SelectContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import ToggleField from 'components/ToggleField';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, useField } from 'react-final-form';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import validators from 'utilities/services/validators';
import ModuleTitleSettings from '../../../ModuleTitleSettings';
import './styles.scss';

const UltimateBeneficialOwnerForm = ({ namePreffix, index }) => {
  const [domainOptions, setDomainOptions] = useState([]);
  const { clientId } = useParams();
  const {
    input: { value: sendEmail },
  } = useField(`${namePreffix}.sendEmail`);

  const {
    input: { onChange: onChangeNpConfigId },
  } = useField(`${namePreffix}.npConfigId`);

  const mappedDomainsList = useMemo(
    () =>
      domainOptions
        ?.reduce((acc, domain) => [...acc, { url: domain?.domains[0]?.url, id: domain?.id }], [])
        .map(domain => ({
          id: domain.id,
          value: domain.url,
          label: domain.url,
        })),
    [domainOptions],
  );

  const handleChangeNpConfig = useCallback(
    value => {
      const configId = mappedDomainsList.find(domain => domain?.value === value)?.id;
      onChangeNpConfigId(configId || null);
    },
    [mappedDomainsList, onChangeNpConfigId],
  );

  useEffect(() => {
    (async () => {
      try {
        let response = null;

        if (clientId) {
          response = await api.businessManager.getDomainsForNaturalPerson(clientId);
        } else {
          response = await api.administration.portalManagement.getDomainsForNaturalPerson();
        }

        setDomainOptions(response.data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <div className="ickyc-ultimate-beneficial-owner-form">
      <ModuleTitleSettings index={index} withDescription namePreffix={'ultimateBeneficialOwner'} />
      <Field component={ToggleField} label="Send email to UBO to complete KYC" name={`${namePreffix}.sendEmail`} />
      {sendEmail && (
        <Field
          component={SelectField}
          name={`${namePreffix}.npDomain`}
          Trigger={
            <SelectTrigger placeholder={`${domainOptions ? 'Choose Natural Person Portal' : 'Loading domains...'}`} />
          }
          Content={<SelectContent />}
          options={[{ id: 'something', value: null, label: 'Unassign Portal' }, ...mappedDomainsList]}
          validate={validators.required()}
          onChange={handleChangeNpConfig}
        />
      )}
    </div>
  );
};
export default UltimateBeneficialOwnerForm;
