import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import ProtectedComponent from 'router/components/ProtectedComponent';
import authEnums from 'utilities/enums/authEnums';
import ToggleField from '../ToggleField';
import './styles.scss';

/**
 * KYC Services
 * @param {boolean} complete - should have all of the toggles
 * @param {boolean} idVerification - should have identityVerification toggle
 * @param {boolean} facialMatching - should have facialMatching toggle
 * @param {boolean} documentAuth - should have documentAuthentication toggle
 * @param {boolean} amlScreening - should have amlScreening toggle
 */

const KYCServices = ({ complete, idVerification, facialMatching, documentAuth, amlScreening }) => {
  return (
    <div className="ickyc-kyc-services">
      <h2 className="ickyc-kyc-services__title"> KYC Services</h2>
      <p className="ickyc-kyc-services__description">
        Selecting these will result in an RCU email being sent for verification.
      </p>

      <div>
        {(complete || idVerification) && (
          <Field component={ToggleField} name="identityVerification" label="Identity Verification" />
        )}

        {(complete || facialMatching) && (
          <Field component={ToggleField} name="facialMatching" label="Biometric Authentication" />
        )}

        {(complete || documentAuth) && (
          <Field component={ToggleField} name="documentAuthentication" label="Document Authentication" />
        )}

        <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.CASES}>
          {(complete || amlScreening) && <Field component={ToggleField} name="amlScreening" label="AML Screening" />}
        </ProtectedComponent>
      </div>
    </div>
  );
};

KYCServices.propTypes = {
  complete: PropTypes.bool,
  idVerification: PropTypes.bool,
  facialMatching: PropTypes.bool,
  documentAuth: PropTypes.bool,
  amlScreening: PropTypes.bool,
};

KYCServices.defaultProps = {
  complete: false,
  idVerification: false,
  facialMatching: false,
  documentAuth: false,
  amlScreening: false,
};

export default KYCServices;
