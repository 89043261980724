import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import EmailMessage from 'scenes/Administration/Emails/components/EmailMessage';
import emailMessageUtils from 'scenes/Administration/Emails/components/EmailMessage/utils';
import utilities from '../../../../../../../api/utilities';
import Accordion from '../../../../../../../components/Accordion';
import InputField from '../../../../../../../components/InputField';
import SelectFilterContent from '../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../components/SelectField';
import Tooltip from '../../../../../../../components/Tooltip';
import rcuEnums from '../../../../../../../utilities/enums/rcuEnums';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';
import IconManager from '../../../../../../../utilities/services/IconManager';
import { RCUContext } from '../../../../../../../utilities/services/contexts';
import validators from '../../../../../../../utilities/services/validators';
import './styles.scss';

const GeneralLegalEntitySettings = ({ domains, errMessage, setSelectedDomain, withRecipient, namePrefix }) => {
  const { emailTooltip } = emailMessageUtils;
  const baseClass = 'ickyc-general-legal-entity-settings';
  const setSuffix = setClassSuffix(baseClass);
  const prefix = useMemo(() => {
    return namePrefix ? `${namePrefix}.` : '';
  }, [namePrefix]);
  const {
    values: {
      [`${prefix}domain`]: domain,
      [`${prefix}recepientId`]: recepientId,
      [`${prefix}emailMessage`]: emailMessage,
    },
  } = useFormState();
  const { batch, change } = useForm();

  const {
    initialConfiguration: {
      emailMessage: emailMessageInitial,
      emailSubject,
      linkText,
      affiliatedEntities,
      supportEmail,
    },
  } = useContext(RCUContext);

  const affiliatedEntitiesMapped = useMemo(() => {
    if (!!affiliatedEntities && affiliatedEntities?.length !== 0) {
      return affiliatedEntities.map(affEntity => ({
        value: affEntity?.id,
        label: affEntity?.name,
        id: affEntity?.id,
      }));
    }
    return [];
  }, [affiliatedEntities]);

  const goTolInk = () => {
    if (domain && domain !== '') window.open(domain.includes('https://') ? domain : `https://${domain}`, '_blank');
  };
  const copyText = () => {
    utilities.copyToClipboard(domain);
  };

  useEffect(() => {
    if (emailSubject) {
      batch(() => {
        change(`${prefix}emailSubject`, emailSubject);
      });
    }

    if (emailMessageInitial) {
      batch(() => {
        change(`${prefix}emailMessage`, emailMessageInitial);
      });
    }
    if (linkText) {
      batch(() => {
        change(`${prefix}linkText`, linkText);
      });
    }
    if (supportEmail) {
      batch(() => {
        change(`${prefix}supportEmail`, supportEmail);
      });
    }
  }, [emailMessageInitial, emailSubject, linkText, supportEmail, batch, change]);

  useEffect(() => {
    setSelectedDomain(domain);
  }, [domain]);

  return (
    <Accordion
      accordionOpen
      accordionindex={rcuEnums.ACCORDION_INDEXES.GENERAL_SETTINGS}
      title={
        <>
          <h3>General Settings</h3>
          <span className="ickyc-spacer" />
          {errMessage && <div className={setSuffix('__error')}>{errMessage}</div>}
        </>
      }
    >
      <div className={baseClass}>
        <div>
          <span className={setSuffix('__label')}>Portal</span>
          <div className={`${setSuffix('__domain-box')} ${setSuffix('__input-box')}`}>
            <Field
              name={`${prefix}domain`}
              component={SelectField}
              options={domains}
              validate={validators.required()}
              Trigger={<SelectTrigger />}
              Content={<SelectFilterContent />}
            />
            <Tooltip
              trigger={
                <div className={setSuffix('__icon')} onClick={copyText}>
                  {IconManager.get(IconManager.names.COPY)}
                </div>
              }
              content={<div>Copy</div>}
            />

            <Tooltip
              trigger={
                <Tooltip
                  trigger={
                    <div className={setSuffix('__icon')} onClick={goTolInk}>
                      {IconManager.get(IconManager.names.LINK)}
                    </div>
                  }
                  content={<div>Open in New Tab</div>}
                />
              }
              content={<div>Copy</div>}
            />
          </div>
        </div>
        {withRecipient && (
          <div>
            <span className={setSuffix('__label')}>Recipient</span>
            <Field
              className={setSuffix('__input-box')}
              name={`${prefix}recepientId`}
              s
              component={SelectField}
              options={affiliatedEntitiesMapped}
              Trigger={<SelectTrigger />}
              Content={<SelectFilterContent />}
              validate={validators.required()}
            />
          </div>
        )}
        <div>
          <span className={setSuffix('__label')}>Email Subject</span>
          <Field
            className={setSuffix('__input-box')}
            name={`${prefix}emailSubject`}
            placeholder="Subject"
            component={InputField}
            validate={validators.required()}
          />
        </div>

        <EmailMessage
          label="Email Message"
          className={setSuffix('__input-box')}
          messageName={`${prefix}emailMessage`}
          linkTextName={`${prefix}linkText`}
          supportEmailName={`${prefix}supportEmail`}
          isLinkRequired
        />
      </div>
    </Accordion>
  );
};
GeneralLegalEntitySettings.propTypes = {
  domains: PropTypes.array,
  errMessage: PropTypes.string,
  setSelectedDomain: PropTypes.func,
  withRecipient: PropTypes.bool,
  namePrefix: PropTypes.string,
};
GeneralLegalEntitySettings.defaultProps = {
  domains: [],
  errMessage: null,
  setSelectedDomain: () => {},
  withRecipient: false,
  namePrefix: '',
};
export default GeneralLegalEntitySettings;
