import AIIcon from 'assets/AIIcon';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Tooltip from 'components/Tooltip';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useField, useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import toastr from 'toastr';
import enums from 'utilities/enums';
import api from '../../../../../api';
import OutlineButton from '../../../../../components/Buttons/OutlineButton';
import TableNavigation from '../../../../../components/TableNavigation';
import AffiliatedEntitiesFields from './components/AffiliatedEntityFields';
import ScrapedOrganizationsPreview from './components/ScrapedOrganizationsPreview';
import './styles.scss';
import { defaultScrapedAffiliationsPagination } from './utils';

const LinkAffiliatedEntitiesStep = () => {
  const [currentEntityindex, setCurrentEntityIndex] = useState(0);
  const [LEEntitiesSuggestions, setLEEntitiesSuggestions] = useState([]);
  const [scrapedAffiliatedEntities, setScrapedAffiliatedEntities] = useState([]);
  const [scrapedAffiliationsPagination, setScrapedAffiliationsPagination] = useState(
    defaultScrapedAffiliationsPagination,
  );
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoadAffiliationsClicked, setIsLoadAffiliationsClicked] = useState(false);

  const { mutators } = useForm();
  const {
    values: { entities },
  } = useFormState();

  const { name, leiNumber } = useMemo(() => {
    return entities[currentEntityindex] || {};
  }, [entities, currentEntityindex]);

  const namePreffix = `entities.${currentEntityindex}.affiliatedEntities`;

  const resetScrapedAffiliationsStates = useCallback(() => {
    setScrapedAffiliatedEntities([]);
    setIsFirstLoad(true);
    setIsLoadAffiliationsClicked(false);
  }, [setScrapedAffiliatedEntities, setIsFirstLoad, setIsLoadAffiliationsClicked]);

  const loadNextEntity = useCallback(() => {
    setCurrentEntityIndex(prev => Math.min(entities.length, prev + 1));
    resetScrapedAffiliationsStates();
  }, [entities.length, resetScrapedAffiliationsStates]);

  const loadPreviousEntity = useCallback(() => {
    setCurrentEntityIndex(prev => Math.max(0, prev - 1));
    resetScrapedAffiliationsStates();
  }, [resetScrapedAffiliationsStates]);

  const addPerson = () => {
    mutators.push(namePreffix, {
      type: enums.ENTITY_TYPE_NAMES.NATURAL_PERSON,
    });
  };

  const {
    input: { value: selectedEntityType },
  } = useField(`${namePreffix}.type`);

  const [params, setParams] = useState({
    sortOrder: 1,
    searchCriteria: 'date',
    limit: 15,
    entityType: 0,
  });

  const loadAffiliatedEntities = useCallback(async () => {
    try {
      setIsLoadingMore(true);
      setIsLoadAffiliationsClicked(true);

      toastr.info('This process may take some time');

      const {
        data: {
          hasMoreData,
          result: { paginationData, data },
        },
      } = await api.kyc.entityManagement.legalEntity.scrapeAffiliatedEntities({
        name,
        leiNumber,
        ...scrapedAffiliationsPagination,
      });
      setScrapedAffiliationsPagination({ ...paginationData, skip: paginationData.skip + paginationData.limit });
      setScrapedAffiliatedEntities(old => [...old, ...data]);
      setHasMoreData(hasMoreData);
    } catch (error) {
      console.error('Failed to load affiliated entities:', error);
      toastr.error('Failed to load affiliated entities');
    } finally {
      if (isFirstLoad) {
        setIsFirstLoad(false);
      }
      setIsLoadingMore(false);
    }
  }, [currentEntityindex, name, leiNumber, scrapedAffiliationsPagination, namePreffix]);

  const changeParams = useCallback(newParams => {
    setParams(prev => ({ ...prev, ...newParams }));
  }, []);

  useEffect(() => {
    const getALLAffiliatedEntitiesSuggestios = () => {
      api.kyc.entityManagement.legalEntity
        .getALLAffiliatedEntitiesSuggestios({
          ...params,
        })
        .then(({ data: response }) => {
          setLEEntitiesSuggestions(
            response.data.map(({ id, name, ...rest }) => ({
              id,
              label: name,
              name,
              value: id,
              ...rest,
            })),
          );
        })
        .finally(() => {});
    };
    if (params.searchString) {
      getALLAffiliatedEntitiesSuggestios();
    }
  }, [params, selectedEntityType]);

  return (
    <div className="ickyc-link-affiliated-entities-form">
      <div className="ickyc-link-affiliated-entities-form__header">
        <h1>Step 2 &nbsp;</h1>
        <h2>Link Affiliated Entities</h2>
      </div>
      <div className="ickyc-link-affiliated-entities-form__current-entity">
        <h1>{currentEntityindex + 1}</h1>
        <h2>{entities[currentEntityindex]?.name}</h2>
        <span className="ickyc-spacer" />
        {leiNumber ? (
          <PrimaryButton
            onClick={loadAffiliatedEntities}
            className="ickyc-link-affiliated-entities-form__current-entity__button"
          >
            <AIIcon />
            Load Affiliated Entities
          </PrimaryButton>
        ) : (
          <Tooltip
            trigger={
              <div>
                <PrimaryButton className="ickyc-link-affiliated-entities-form__current-entity__button" disabled>
                  <AIIcon />
                  Load Affiliated Entities
                </PrimaryButton>
              </div>
            }
            content={<span>Company must contain LEI number in order to load affiliated entities</span>}
            placement="top"
          />
        )}
        <TableNavigation
          totalNumber={entities.length}
          pageNumber={currentEntityindex + 1}
          goToNextPage={loadNextEntity}
          goToPrevPage={loadPreviousEntity}
          pageSize={1}
        />
      </div>

      {isLoadAffiliationsClicked && (
        <ScrapedOrganizationsPreview
          scrapedAffiliatedEntities={scrapedAffiliatedEntities}
          loadAffiliatedEntities={loadAffiliatedEntities}
          setScrapedAffiliatedEntities={setScrapedAffiliatedEntities}
          scrapedAffiliationsPagination={scrapedAffiliationsPagination}
          setScrapedAffiliationsPagination={setScrapedAffiliationsPagination}
          entitiesTableName={namePreffix}
          hasMoreData={hasMoreData}
          isFirstLoad={isFirstLoad}
          isLoadingMore={isLoadingMore}
        />
      )}
      <div className="ickyc-link-affiliated-entities-form__fields">
        <FieldArray name={namePreffix}>
          {({ fields, meta: { submitError, dirtySinceLastSubmit } }) => {
            return fields.map((name, index) => (
              <AffiliatedEntitiesFields
                className="ickyc-link-affiliated-entities-form__fields__row"
                key={`${name}${currentEntityindex}`}
                index={index}
                namePreffix={name}
                entities={LEEntitiesSuggestions}
                removeRow={i => fields.remove(i)}
                currentEntityindex={currentEntityindex}
                changeParams={changeParams}
                submitError={submitError}
                dirtySinceLastSubmit={dirtySinceLastSubmit}
                isOnlyAffilitedEntity={fields.length === 1}
              />
            ));
          }}
        </FieldArray>
      </div>

      <OutlineButton onClick={addPerson} className="ickyc-link-affiliated-entities-form__add-row">
        + Add Row
      </OutlineButton>
    </div>
  );
};

export default LinkAffiliatedEntitiesStep;
