import { CircularProgress } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useForm, useFormState } from 'react-final-form';
import toastr from 'toastr';
import enums from 'utilities/enums';
import OrganizationsTable from '../../../AddLegalEntityStep/components/OrganizationSearchForm/components/OrganizationsTable';

const ScrapedOrganizationsPreview = ({
  scrapedAffiliatedEntities,
  loadAffiliatedEntities,
  entitiesTableName,
  hasMoreData,
  isFirstLoad,
  isLoadingMore,
}) => {
  const { mutators } = useForm();
  const { values } = useFormState();

  const addAffiliation = useCallback(
    affiliate => {
      const { name } = affiliate;

      mutators.push(entitiesTableName, {
        name: name,
        entitySearch: name,
        type: enums.ENTITY_TYPE_NAMES.LEGAL_ENTITY,
        primaryContact: false,
        date: undefined,
        id: undefined,
        residenceId: undefined,
        email: '',
      });

      toastr.success(`${name} was added as affiliation successfully`);
    },
    [entitiesTableName, mutators, values],
  );

  const handleLoadMore = useCallback(() => {
    if (hasMoreData) {
      loadAffiliatedEntities();
    }
  }, [loadAffiliatedEntities, hasMoreData]);

  return (
    <div className="ickyc-link-affiliated-entities-form__scraped-organization-box">
      {isFirstLoad ? (
        <>
          <CircularProgress size="28px" />
          <p>Loading Organizations...</p>
        </>
      ) : (
        <OrganizationsTable
          entitiesTableName={entitiesTableName}
          onAddOrganization={addAffiliation}
          isLoading={isLoadingMore}
          onLoadMore={handleLoadMore}
          records={scrapedAffiliatedEntities}
        />
      )}
    </div>
  );
};

export default ScrapedOrganizationsPreview;
